import instance from "@/axios"

/** 客户端推送IM消息 */
export const pushAppIMMessageApi = async (params) => {
    return instance({
        url: '/dq_admin/platformPushDetail/im/push',
        method: 'POST',
        data: params,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
}