var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "push-message-manage" },
    [
      _c(
        "a-form",
        { attrs: { layout: _vm.formLayout } },
        [
          _c(
            "a-form-item",
            {
              attrs: {
                label: "推送标题",
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入推送标题" },
                model: {
                  value: _vm.pushParams.title,
                  callback: function($$v) {
                    _vm.$set(_vm.pushParams, "title", $$v)
                  },
                  expression: "pushParams.title"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "推送内容描述",
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入描述" },
                model: {
                  value: _vm.pushParams.content,
                  callback: function($$v) {
                    _vm.$set(_vm.pushParams, "content", $$v)
                  },
                  expression: "pushParams.content"
                }
              })
            ],
            1
          ),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "打开路径",
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c(
                "a-select",
                {
                  staticStyle: { "margin-right": "8px" },
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    placeholder: "请选择打开路径",
                    allowClear: ""
                  },
                  on: { change: _vm.handleChangeSelectedOpenUrlType },
                  model: {
                    value: _vm.openUrlType,
                    callback: function($$v) {
                      _vm.openUrlType = $$v
                    },
                    expression: "openUrlType"
                  }
                },
                _vm._l(_vm.openUrlList, function(item) {
                  return _c(
                    "a-select-option",
                    { key: item.id, attrs: { value: item.id } },
                    [_vm._v(_vm._s(item.name))]
                  )
                }),
                1
              )
            ],
            1
          ),
          ["testH5", "proH5"].includes(_vm.openUrlType)
            ? _c(
                "a-form-item",
                {
                  attrs: {
                    label: "推送打开地址",
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入推送打开地址" },
                    model: {
                      value: _vm.pushParams.linkUrl,
                      callback: function($$v) {
                        _vm.$set(_vm.pushParams, "linkUrl", $$v)
                      },
                      expression: "pushParams.linkUrl"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            {
              attrs: {
                label: "推送类型",
                "label-col": _vm.formItemLayout.labelCol,
                "wrapper-col": _vm.formItemLayout.wrapperCol
              }
            },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "default-value": _vm.pushParams.testType },
                  on: { change: _vm.handlePushChangeType }
                },
                [
                  _c("a-radio-button", { attrs: { value: 0 } }, [
                    _vm._v("正式推送")
                  ]),
                  _c("a-radio-button", { attrs: { value: 1 } }, [
                    _vm._v("测试环境测试推送")
                  ]),
                  _c("a-radio-button", { attrs: { value: 2 } }, [
                    _vm._v("正式环境测试推送")
                  ])
                ],
                1
              )
            ],
            1
          ),
          [0].includes(_vm.pushParams.testType)
            ? _c(
                "a-form-item",
                {
                  attrs: {
                    label: "上传推送用户文件",
                    "label-col": _vm.formItemLayout.labelCol,
                    "wrapper-col": _vm.formItemLayout.wrapperCol
                  }
                },
                [
                  _c(
                    "a-upload",
                    {
                      attrs: {
                        "file-list": _vm.fileList,
                        remove: _vm.handleRemove,
                        "before-upload": _vm.beforeUpload,
                        accept:
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      }
                    },
                    [
                      _vm.fileList.length < 1
                        ? _c(
                            "a-button",
                            [
                              _c("a-icon", { attrs: { type: "upload" } }),
                              _vm._v(" 选择用户模版文件 ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("a-badge", {
                        staticStyle: { "margin-left": "10px" },
                        attrs: { color: "red", text: "请选择.xls文件" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-item",
            { attrs: { "wrapper-col": _vm.buttonLayout.wrapperCol } },
            [
              _vm.pushParams.testType === 0
                ? _c(
                    "a-popconfirm",
                    {
                      attrs: {
                        title: "是否确认群发推送?",
                        "ok-text": "Yes",
                        "cancel-text": "No"
                      },
                      on: {
                        confirm: function($event) {
                          return _vm.handleConfirmPushImMessage()
                        }
                      }
                    },
                    [
                      _c("a-button", { attrs: { type: "primary" } }, [
                        _vm._v("群发推送")
                      ])
                    ],
                    1
                  )
                : _c(
                    "a-button",
                    {
                      attrs: { type: "defalut" },
                      on: { click: _vm.handleConfirmPushImMessage }
                    },
                    [_vm._v("测试一下")]
                  ),
              _vm.pushOverResultFile
                ? _c(
                    "a-button",
                    {
                      staticClass: "ml-20",
                      attrs: { type: "danger", icon: "download" },
                      on: { click: _vm.handleDownloadPushOverResult }
                    },
                    [_vm._v("下载推送结果")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }